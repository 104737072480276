// App.js
import { BrowserRouter as Router, Route, useLocation } from 'react-router-dom'; // include useLocation
import Place from './components/Place';
import Navbar from './components/Navbar';
import WelcomeModal from './components/WelcomeModal';
import Portfolio from './components/Portfolio';
import SongDetails from './components/SongDetails';
import SongDataContext from './contexts/SongDataContext';
import React, { useState, useEffect } from "react";
import { Flipper } from 'react-flip-toolkit'; // Make sure you have Flipper imported
import './App.css';
import { pushRotate as Menu } from 'react-burger-menu';
import { BiMenu } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import HighFiveBotDetails from './components/HighFiveBotDetails'; // Make sure you import your HighFiveBotDetails component

// This is the component that will handle our routes
const MainRoutes = () => {
  const location = useLocation();
  
  return (
    <Flipper flipKey={location.pathname}>
      <Route exact path="/portfolio">
        <Portfolio />
      </Route>
      <Route exact path="/">
        <Place />
      </Route>
      <Route exact path="/highFiveBot">
        <HighFiveBotDetails />
      </Route>
    </Flipper>
  );
};

function App() {
  const [modalShow, setModalShow] = useState(true);
  const [songData, setSongData] = useState(null);
  const [hover, setHover] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false); 

  const closeModal = () => {
    setModalShow(false);
  };

  function toggleScrollable() {
    if (isMenuOpen) {
      setIsMenuOpen(false);
      document.body.setAttribute("style", "position: fixed;");
    }
    else {
      setIsMenuOpen(true);
      document.body.setAttribute("style", "position: static;");
    }
  }

  useEffect(() => {
    const button = document.querySelector('.bm-burger-button');
    if (modalShow) {
      button.style.display = 'none';
    } else {
      button.style.display = 'block';
    }
  }, [modalShow, isMenuOpen]);

  return (
    <div id="outer-container">
      <WelcomeModal className='bigmodal' show={modalShow} close={closeModal} />
      <SongDataContext.Provider value={{ songData, setSongData, hover, setHover }}>
        <Router>
          <Menu
            pageWrapId="page-wrap"
            outerContainerId="outer-container"
            customBurgerIcon={<BiMenu />}
            onStateChange={toggleScrollable} 
          >
            <Link className="nav-item" to="/">/</Link>
            <Link className="nav-item" to="/portfolio">/portfolio</Link>
            <Link className="nav-item" to="/about">/about</Link>
          </Menu>
          
          <main
            id="page-wrap"
            className={isMenuOpen ? 'menu-open' : ''}
          >
            <Navbar /> 
            <div className="songpopupcontainer">
              <SongDetails className="songpopup"/>  
            </div>
            <MainRoutes /> 
          </main>
        </Router>
      </SongDataContext.Provider>
    </div>
  );
}

export default App;
