// HighFiveBotDetails.js
import { Flipped } from 'react-flip-toolkit';
import './HighFiveBotDetails.css';

const HighFiveBotDetails = () => {
  return (
    <Flipped flipId="highFiveBot">
        <div className="largeapp-details">  {/* add onClick */}     
        <Flipped flipId="header">
            <h1 className="app-header">🎊 Highfive Bot</h1>
        </Flipped>           
        <Flipped flipId="description">
            <p className="app-body">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
        </Flipped>
        </div>
    </Flipped> 
  );
};

export default HighFiveBotDetails;