// Portfolio.js
import React, { useEffect, useRef } from 'react';
import './Portfolio.css';
import { Flipped } from 'react-flip-toolkit';
import { useHistory } from 'react-router-dom';

const Portfolio = () => {

  const history = useHistory();  // add this line

  const navigateTo = (path) => {
    history.push(path);
  };

  const containerRef = new useRef();

  const [frameDimensions, setFrameDimensions] = React.useState({
    width: 1205, 
    height: 730
  });

  const handleResize = () => {
    if (window.innerWidth < 1270) {
      setFrameDimensions({ width: 603, height: 1335 });
    } else {
      setFrameDimensions({ width: 1205, height: 730 });
    }
  };

  React.useEffect(() => {
    // Call once when the component mounts
    handleResize();
  
    // Set the handleResize function as an event listener
    window.addEventListener('resize', handleResize);
  
    // Remove the event listener when the component unmounts
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  

  useEffect(() => {
    const handleMouseMove = (e) => {
      const rect = containerRef.current.getBoundingClientRect();
      containerRef.current.style.setProperty('--x', `${e.clientX - rect.left}px`);
      containerRef.current.style.setProperty('--y', `${e.clientY - rect.top}px`);
      const boxes = document.querySelectorAll('.box');
    boxes.forEach(box => {
      const rect = box.getBoundingClientRect();
      box.style.setProperty('--x', `${e.clientX - rect.left}px`);
      box.style.setProperty('--y', `${e.clientY - rect.top}px`);
    }); 
    };

    document.documentElement.addEventListener('mousemove', handleMouseMove);

    return () => {
      document.documentElement.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  const Box = () => {
    return (
      <div className="box">
        <div className="frame-wrapper">
        <div className="frame" style={{ width: frameDimensions.width, height: frameDimensions.height }}>
            <Flipped flipId="highFiveBot">
              <div className="largeapp" onClick={() => navigateTo("/highFiveBot")}>  {/* add onClick */}     
                <Flipped flipId="header">
                  <h1 className="app-header">🎊 Highfive Bot</h1>
                </Flipped>           
                <Flipped flipId="description">
                  <p className="app-body">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                </Flipped>
              </div>
            </Flipped> 
            <div className="mediumapp">
              <h1 className="app-header">🕒 Coming soon</h1>
              <p className="app-body">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            </div>
            <div className="div">
            <h1 className="app-header">🕒 Coming soon</h1>
              <p className="app-body">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

            </div>
            <div className="mediumapp-2">
            <h1 className="app-header">🕒 Coming soon</h1>
              <p className="app-body">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

            </div>
            <div className="mediumapp-3">
            <h1 className="app-header">🕒 Coming soon</h1>
              <p className="app-body">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

            </div>
            <div className="largeapp-2">
              <h1 className="app-header">🕒 Coming soon</h1>
              <p className="app-body">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
            </div>
            <div className="smallapp">
            </div>
            <div className="smallapp-2">
            </div>
            <div className="smallapp-3">
            </div>
            <div className="smallapp-4">
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="portfolio-page-wrapper" ref={containerRef}>
        <div className="header">
            <h1 className="text">View My Work</h1>
        </div>
        <div className="appsContainer">
            <Box></Box>
        </div>
    </div>
  );
};

export default Portfolio;